import { Transaction } from "@runonbitcoin/nimble";
import { useCallback, useEffect, useState } from "react";
import AceEditor from "react-ace";
import sb from "satoshi-bitcoin";
import tw from "twin.macro";
import "./App.css";
import { useRelay } from "./context/relay";
import logo from "./logo.svg";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
import { useLocalStorage } from "./utils/storage";

const enum Tab {
  Single = "single",
  Channel = "channel",
}

const enum Subtab {
  Go = "single",
  Node = "node",
}

const Heading = tw.div`
  text-2xl
`;

const serverExampleGo = new Map<Tab, string>();
const serverExampleNode = new Map<Tab, string>();

serverExampleNode.set(
  Tab.Single,
  `var express = require('express');
var app = express();

app.get('/httpay_test', function (req, res) {

  // Check for Bitcoin-Payment header
  const payment = req.get(\`Bitcoin-Payment\`)

  if (!payment) {
    // No payment found, response with 402 and bitcoin-payment-template header
    // TODO
    const tx = new Transaction()
    const paymentOutputs = new Output({})
    tx.addOutput(paymentOutput)
    const template b64 = tx.tob64()
    res.status(402).send(templateb64)
  } else {
    // Payment exists. Make sure it's valid
    // TODO
    // Send paywalled content
    res.send('Hello World!');
  }

  
});

app.listen(3000);`
);

serverExampleNode.set(
  Tab.Channel,
  `var express = require('express');
var app = express();

app.get('/httpay_test', function (req, res) {

  // Check for Bitcoin-Payment header
  const payment = req.get(\`Bitcoin-Payment\`)

  if (!payment) {
    // No payment found, response with 402 and bitcoin-payment-template header
    // TODO
    const tx = new Transaction()
    const paymentOutputs = new Output({})
    tx.addOutput(paymentOutput)
    const template b64 = tx.tob64()
    res.status(402).send(templateb64)
  } else {
    // Payment exists. Make sure it's valid
    // TODO
    // Send paywalled content AND next payment template
    res.setHeader() // TODO
    res.send('Hello World!');
  }

  
});

app.listen(3000);`
);

const clientExample = new Map<Tab, string>();

clientExample.set(
  Tab.Single,
  `
// Fetch to httpay_test with no payment to get the template

// Add inputs

// Sign

// Fetch to httpay_test with a valid Bitcoin-Payment header

// Profit
`
);

clientExample.set(
  Tab.Channel,
  `
// Fetch to httpay_test with no payment to get the template

// Add inputs

// Sign

// Fetch to httpay_test with a valid Bitcoin-Payment header

// Process the new template 

// Prepare next request

// Fetch to httpay_test with a valid Bitcoin-Payment header

`
);

const tabs = [
  {
    key: Tab.Single,
    name: "Single Request",
  },
  {
    key: Tab.Channel,
    name: "Payment Channel",
  },
];

const subtabs = [
  {
    key: Subtab.Node,
    name: "Node",
  },
  {
    key: Subtab.Go,
    name: "Go",
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface Output {
  amount: number; // number. required.
  script: string; // string. required. hexadecimal script.
  description?: string; // string. optional. must not have JSON string length of greater than 100.
}

interface PaymentRequest {
  network: string; //required. always set to "bitcoin".
  outputs: Output[]; // an array of outputs. required, but can have zero elements.
  creationTimestamp: number; // number. required.
  expirationTimestamp: number; // number. optional.
  memo: string; // string. optional.
  paymentUrl: string; // string. required.
  merchantData?: string; // string. optional.
}

function App() {
  const { relayOne, paymail, authenticate } = useRelay();
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.Single);
  const [showAuthorize, setShowAuthorize] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [selectedSubtab, setSelectedSubtab] = useState<Subtab>(Subtab.Node);
  const [paywalledContent, setPaywalledContent] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [selectedCharacter, setSelectedCharacter] = useState<string>("cloud");
  const [paymentRequest, setPaymentRequest] = useState<
    PaymentRequest | undefined
  >();
  const [paidTx, setPaidTx] = useLocalStorage<string>("hp__paidTx", undefined);
  const [button, setButton] = useState<string>(
    paidTx ? "Retrieve Purchase" : "Buy ASCII Art"
  );

  const Tabs: React.FC = () => {
    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue={tabs.find((tab) => selectedTab)?.name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="flex space-x-4" aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                key={tab.key}
                onClick={() => setSelectedTab(tab.key)}
                className={classNames(
                  selectedTab === tab.key
                    ? "bg-gray-100 text-gray-700"
                    : "cursor-pointer text-gray-500 hover:text-gray-700",
                  "px-3 py-2 font-medium text-sm rounded-md"
                )}
                aria-current={selectedTab ? "page" : undefined}
              >
                {tab.name}
              </div>
            ))}
          </nav>
        </div>
      </div>
    );
  };

  const SubTabs: React.FC = () => {
    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue={subtabs.find((tab) => selectedSubtab)?.name}
          >
            {subtabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="flex space-x-4" aria-label="Tabs">
            {subtabs.map((tab) => (
              <div
                key={tab.key}
                onClick={() => setSelectedSubtab(tab.key)}
                className={classNames(
                  selectedSubtab === tab.key
                    ? "bg-gray-100 text-gray-700"
                    : "cursor-pointer text-gray-500 hover:text-gray-700",
                  "px-3 py-2 font-medium text-sm rounded-md"
                )}
                aria-current={selectedSubtab ? "page" : undefined}
              >
                {tab.name}
              </div>
            ))}
          </nav>
        </div>
      </div>
    );
  };

  const fillPaymentRequest = useCallback(
    async (paymentRequest: PaymentRequest) => {
      console.log("Filling template", paymentRequest, relayOne);

      // Decode b64
      // const templateBuffer = bops.from(paymentRequest, "base64");

      // Load template
      const tx = new Transaction();

      paymentRequest.outputs.forEach((o) =>
        tx.output(new Transaction.Output(o.script, o.amount))
      );

      console.log(tx.outputs.map((o) => o.script?.toHex()));

      // Collect scripts
      const outputs = tx.outputs.map((o) => {
        console.log(o.satoshis);
        return {
          amount: sb.toBitcoin(o.satoshis),
          to: o.script.toASM(),
          currency: "BSV",
        };
      });

      // RelayX Wallet will add inputs and sign
      if (relayOne) {
        setButton("Sending Payment");
        const resp = await relayOne.send({
          outputs,
        });
        // const resp = await relayOne.send({
        //   outputs,
        // });
        setButton(`TxID: ${resp.txid}`);
        console.log({ resp });
        // const buf = bops.from(resp.rawTx, "hex");

        // return bops.to(buf, "base64");
        // Transaction.fromHex(rawTx)

        // return bops.resp.rawTx;
        return resp.rawTx;
      }
    },
    [relayOne]
  );

  useEffect(() => {
    console.log({ paidTx });
  }, [paidTx]);

  // const fetchPaywalledContent = useCallback(
  //   async (tx: string | undefined): Promise<any> => {
  //     var headers: HeadersInit = {
  //       "Content-Type": "application/json",
  //     };
  //     console.log("from cache?", paidTx, tx);

  //     if (tx) {
  //       headers["bitcoin-payment"] = tx;
  //     }

  //     // BIP270

  //     //   PaymentRequest {
  //     //     network // string. required. always set to "bitcoin".
  //     //     outputs // an array of outputs. required, but can have zero elements.
  //     //     creationTimestamp // number. required.
  //     //     expirationTimestamp // number. optional.
  //     //     memo // string. optional.
  //     //     paymentUrl // string. required.
  //     //     merchantData // string. optional.
  //     // }

  //     // Output {
  //     //   amount // number. required.
  //     //   script // string. required. hexadecimal script.
  //     //   description // TWO different explainations in docs: [`string. optional. must not have JSON string length of greater than 100.`,`An optional description such as "tip" or "sales tax". Maximum length is 50 characters.`]
  //     // }

  //     // ===============

  //     // Payment {
  //     //   merchantData // string. optional.
  //     //   transaction // a hex-formatted (and fully-signed and valid) transaction. required.
  //     //   refundTo // string. paymail to send a refund to. optional.
  //     //   memo // string. optional.
  //     // }

  //     // ===============

  //     // ACK
  //     //   PaymentACK {
  //     //     payment // Payment. required.
  //     //     memo // string. optional.
  //     //     error // number. optional.
  //     // }

  //     try {
  //       const response = await fetch(`https://httpay.dev/paymentRequest`, {
  //         mode: "cors",
  //         method: "post",
  //         headers,
  //         body: JSON.stringify({
  //           character: selectedCharacter,
  //         }),
  //       });

  //       const res = await response.json();
  //       if (response.status === 402) {
  //         setShowAuthorize(true);
  //       }

  //       if (response.headers.has("bitcoin-payment-template")) {
  //         const newTemplate = response.headers.get("bitcoin-payment-template");

  //         // Recursive fetch
  //         if (newTemplate) {
  //           if (response.status === 402) {
  //             const e = await response.json();
  //             setError(`${response.status}: ${e.error}`);
  //           }
  //           setMessage("Completing payment template using RelayX wallet");
  //           setButton("Completing Template");
  //           setTemplate(newTemplate);
  //           const newTx = await fillTemplate(newTemplate);
  //           if (newTx) {
  //             const content = await fetchPaywalledContent(newTx);
  //             console.log("content was", content);
  //             setPaywalledContent(content);
  //             setPaidTx(newTx);
  //             setButton("Do it again");
  //           }
  //         }
  //       }
  //       return await response.text();
  //     } catch (e: any | undefined) {
  //       console.log("set error", e);
  //       setError(e.error);
  //     }
  //   },
  //   [paidTx, fillTemplate, selectedCharacter, setPaidTx]
  // );

  const authorizePayment = useCallback(async () => {
    if (!paymentRequest) {
      return;
    }

    const newTx = await fillPaymentRequest(paymentRequest);
    if (newTx) {
      var headers: HeadersInit = {
        "Content-Type": "application/bitcoinsv-payment",
        Accept: "application/bitcoinsv-paymentack",
      };

      const paymentAck = await fetch(paymentRequest.paymentUrl, {
        method: "POST",
        mode: "cors",
        headers,
        body: JSON.stringify({
          transaction: newTx,
          merchantData: paymentRequest.merchantData,
          refundTo: paymail,
          memo: "Payment for ACII Art demo",
        }),
      });
      const { payment, memo, error, content } = await paymentAck.json();

      console.log("ack was was", { payment, memo, error, content });
      setPaywalledContent(content);
      setPaidTx(newTx);
      setButton("Do it again");
      setShowAuthorize(false);
    }
  }, [paymail, setPaidTx, paymentRequest, fillPaymentRequest]);

  const getPaymentRequest = useCallback(async (): Promise<any> => {
    var headers: HeadersInit = {
      "Content-Type": "application/json",
    };
    // console.log("from cache?", paidTx, tx);

    // if (tx) {
    //   headers["bitcoin-payment"] = tx;
    // }

    // BIP270 PaymentRequest
    //   PaymentRequest {
    //     network // string. required. always set to "bitcoin".
    //     outputs // an array of outputs. required, but can have zero elements.
    //     creationTimestamp // number. required.
    //     expirationTimestamp // number. optional.
    //     memo // string. optional.
    //     paymentUrl // string. required.
    //     merchantData // string. optional.
    // }

    // Output {
    //   amount // number. required.
    //   script // string. required. hexadecimal script.
    //   description // TWO different explainations in docs: [`string. optional. must not have JSON string length of greater than 100.`,`An optional description such as "tip" or "sales tax". Maximum length is 50 characters.`]
    // }

    // Payment {
    //   merchantData // string. optional.
    //   transaction // a hex-formatted (and fully-signed and valid) transaction. required.
    //   refundTo // string. paymail to send a refund to. optional.
    //   memo // string. optional.
    // }

    // ACK
    //   PaymentACK {
    //     payment // Payment. required.
    //     memo // string. optional.
    //     error // number. optional.
    // }

    try {
      const response = await fetch(`https://httpay.dev/paymentRequest`, {
        method: "post",
        mode: "cors",
        headers,
        body: JSON.stringify({
          character: selectedCharacter,
        }), // TODO: Let UI select the ascii character they want to buy
      });

      const pr = await response.json();
      console.log({ pr });
      if (pr) {
        setMessage("Completing payment template using RelayX wallet");
        setButton("Completing Template");
        setPaymentRequest(pr);
        setShowAuthorize(true);
      }

      // if (response.headers.has("bitcoin-payment-template")) {
      //   const newTemplate = response.headers.get("bitcoin-payment-template");

      //   // Recursive fetch
      //   if (newTemplate) {
      //     if (response.status === 402) {
      //       const e = await response.json();
      //       setError(`${response.status}: ${e.error}`);
      //     }
      //     setMessage("Completing payment template using RelayX wallet");
      //     setButton("Completing Template");
      //     setPaymentRequest(newTemplate);
      //   }
      // }
      // return await response.text();
    } catch (e: any | undefined) {
      console.log("set error", e);
      setError(e.error);
    }
  }, [setShowAuthorize, selectedCharacter]);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="p-4 md:max-w-6xl mx-auto text-slate-400 italic">
          A BIP270 paywall demo using React and NodeJS.
        </p>
      </header>
      {/* <main className="w-full h-screen text-left">
        <div className="flex max-w-5xl mx-auto">
          <div className="flex-1 mb-4">
            <div className="rounded bg-slate-400 text-white p-4 text-lg mr-4">
              Client
            </div>
            <div className="mr-4"></div>
          </div>
          <div className="w-14">&nbsp;</div>
          <div className="flex-1 mb-4">
            <div className="rounded bg-teal-400 text-white p-4 text-lg ml-4">
              Server
            </div>
            <div className="ml-4"></div>
          </div>
        </div>
        <div className="flex max-w-5xl mx-auto">
          <div className="flex-1 mb-4 flex flex-col mr-4">
            <Heading className="mb-4">Initial Request</Heading>
            <p className="mb-4">
              Clients make initial requests as usual. Check the API
              documentation, and craft your api request without including a
              payment header.
            </p>
            <div className="bg-slate-200 p-2 mb-4 font-mono">
              Method: any
              <br />
              Content-Type: any
              <br />
              Body: any
            </div>
            <p className="mb-4">
              You can use the payment template to display requirements to a
              user, or automatically make a payment if the cost is within
              certain parameters, etc.
            </p>
          </div>
          <div className="mb-4 flex flex-col ml-2 mr-2">
            <div className="flex-1 flex text-xl">
              <BsFillPatchQuestionFill className="mb-2 text-orange-400" />
              <FaChevronRight className="text-slate-500" />
            </div>
            <div className="flex-1 flex text-xl">
              <FaChevronLeft className="text-slate-500" />
              <ImInsertTemplate className="text-slate-500" />
            </div>
          </div>
          <div className="flex-1 mb-4 flex flex-col ml-4">
            <Heading className="mb-4">Template Response</Heading>
            <p className="mb-4">
              The server responds with HTTP Status 402 Payment Required and a
              base64 encoded payment template in the response header.
            </p>

            <div className="bg-teal-200 p-2 mb-4 font-mono">
              HTTP Status: 402
              <br />
              {`Header: { bitcoin-payment-template: <base64> }`}
              <br />
              Body: none
            </div>
            <p className="mb-4">
              Endpoint requirements should be checked BEFORE sending a 402 when
              payment header is absent. This allows clients to build, test and
              validate requests without payments.
            </p>
          </div>
        </div>
        <hr className="mb-8 max-w-5xl mx-auto" />
        <div className="flex max-w-5xl mx-auto">
          <div className="flex-1 mb-4 flex flex-col mr-4">
            <Heading className="mb-4">Content Request (Payment)</Heading>
            <p className="mb-4">
              The client completes the payment template by supplying enough
              inputs to cover the outputs and signing. Include the resulting
              transaction as a base64 string in the header.
            </p>
            <div className="bg-slate-200 p-2 mb-4 font-mono rounded">
              Method: any
              <br />
              Content-Type: any
              <br />
              {`Header: { bitcoin-bayment: <base64> }`}
              <br />
              Body: any
            </div>
            <p className="mb-4">Enjoy your response data!</p>

            <p className="mb-4">
              If this was the start of a new payment channel, the response will
              also include an updated payment template in the response header.
              Continue adding inputs and requesting additional data until you
              are done. Either party can broadcast the transaction at any time,
              closing the payment channel.
            </p>
          </div>
          <div className="mb-4 flex flex-col ml-2 mr-2">
            <div className="flex-1 flex text-xl">
              <SiBitcoinsv className="mb-2 text-yellow-500" />
              <FaChevronRight className="text-slate-500" />
            </div>
            <div className="flex-1 flex text-xl">
              <FaChevronLeft className="text-slate-500" />
              <HiOutlineDocumentDuplicate className="text-slate-500" />
            </div>
          </div>
          <div className="flex-1 mb-4 flex flex-col ml-4">
            <Heading className="mb-4">Paid Content Response</Heading>
            <p className="mb-4">
              The backend parses the supplied payment and verifies the template
              was used, the inputs have been supplied and the transaction is
              fully signed and valid. The backend should also check that the
              inputs have been included in blocks via SPV, and optionally check
              confirmation requirements.
            </p>

            <div className="bg-teal-200 p-2 mb-4 font-mono">
              HTTP Status: any success status
              <br />
              Content-Type: any
              <br />
              {`Body: <paywalled content>`}
            </div>

            <p className="mb-4">
              If this is the start of a new payment channel, you would also
              include an updated payment template in the response header.
            </p>
            <div className="bg-teal-200 p-2 mb-4 font-mono">
              {`Header: { bitcoin-payment-template: <base64> }`}
            </div>
          </div>
        </div>
      </main> */}

      <hr className="mb-8 max-w-5xl mx-auto" />

      <div className="flex flex-col items-center max-w-5xl mx-auto mb-12">
        <Heading className="mb-8">Build Payment APIs For</Heading>
        <ul className="md:columns-3 text-lg font-thin">
          <li>News articles</li>
          <li>IOT devices</li>
          <li>Metered Services</li>
          <li>Multimedia</li>
          <li>Physical Merchandise</li>
          <li>Token payments</li>
          <li>Streaming Video</li>
          <li>Pay Once Read Many</li>
          <li>Authless Sales</li>
        </ul>
      </div>
      <hr className="mb-8 max-w-5xl mx-auto" />

      <div className="flex flex-col items-center max-w-5xl mx-auto mb-12 min-h-screen justify-center">
        <Heading className="mb-4">Try it out!</Heading>

        {paymail && (
          <div className="rounded mx-auto text-sm bg-purple-100 text-slate-500 mb-4 py-2 px-4">
            <span className="font-bold">Endpoint</span>:
            https://http.dev/paywall
          </div>
        )}

        {paymail && paidTx && (
          <div className="rounded mx-auto text-sm bg-teal-100 text-slate-500 mb-4 py-2 px-4">
            <span className="font-bold">Already Paid! Thank you!</span>:
            https://http.dev/paywall
          </div>
        )}

        {!paymail && (
          <>
            <div
              className="mb-4 px-4 py-2 hover:bg-blue-700 transition-all rounded text-white bg-blue-600 mx-auto cursor-pointer"
              onClick={async () => {
                try {
                  await authenticate();
                } catch (e) {
                  console.error(e);
                  setError((e as string) || undefined);
                }
              }}
            >
              A RelayX Wallet is required for the demo.
            </div>

            <a
              className="text-blue-400 hover:text-blue-500"
              href="https://relayx.io"
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </a>
          </>
        )}
        {error && (
          <div className="p-4 bg-yellow-200 text-slate-600 text-sm mb-4">
            {error}
          </div>
        )}
        {message && (
          <div className="p-4 bg-slate-200 text-slate-600 text-sm mb-4">
            {message}
          </div>
        )}
        <pre className="font-mono rounded bg-gray-200 mx-auto mb-4">
          {paywalledContent}
        </pre>
        {paymail && (
          <div className="p-2 bg-slate-100 rounded mx-auto mb-8 h-72 flex text-slate-600 items-center justify-center p-12 flex-col">
            <p className="mb-4">Choose a character to buy the ASCII art.</p>
            <select
              className="border rounded mb-4 p-2 text-slate-800"
              onChange={(e) => setSelectedCharacter(e.target.value)}
            >
              <option value="cloud">Cloud Strife - 1000 Sat</option>
              <option value="vivi">Vivi - 2000 Sat</option>
            </select>
          </div>
        )}
        {paymail && (
          <div className="flex flex-col">
            <button
              className="p-2 rounded bg-slate-400 text-white font-semibold"
              onClick={async () => {
                setError("");
                setMessage("");
                setButton("Authenticating");
                if (!paymail) {
                  try {
                    await authenticate();
                  } catch (e: any) {
                    console.error(e);
                    setError(e as string | undefined);
                    return;
                  }
                }
                setButton("Fetching Content");

                await getPaymentRequest();
              }}
            >
              {button}
            </button>
          </div>
        )}
      </div>
      <hr className="mb-8 max-w-5xl mx-auto" />

      <div className="flex flex-col items-center max-w-5xl mx-auto mb-12">
        <Heading>HTTPay Client Example (Javascript)</Heading>
        <Tabs />

        <AceEditor
          mode="javascript"
          theme="github"
          width={`100%`}
          onChange={() => console.log("editor changed")}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
          value={clientExample.get(selectedTab)}
        />
      </div>
      <hr className="mb-8 max-w-5xl mx-auto" />

      <div className="flex flex-col items-center max-w-5xl mx-auto mb-12">
        <Heading>HTTPay Server Example (Express)</Heading>
        <Tabs />
        <SubTabs />
        <AceEditor
          mode="javascript"
          theme="github"
          onChange={() => console.log("editor changed")}
          name="UNIQUE_ID_OF_DIV2"
          editorProps={{ $blockScrolling: true }}
          width={`100%`}
          value={serverExampleNode.get(selectedTab)}
        />
      </div>
      <hr className="mb-8 max-w-5xl mx-auto" />

      <div className="flex flex-col items-center max-w-5xl mx-auto mb-12">
        <Heading>Complex Transaction Support</Heading>
        <div className="text-left text-lg">
          While HTTP does not enforce a size limit for header values, you may
          need to adjust your web server settings to support large transaction
          templates.
        </div>
      </div>
      <div className="flex flex-col items-center max-w-5xl mx-auto mb-12">
        <Heading>Payment Channels</Heading>
        <div className="text-left text-lg">
          This spec will be updated soon with payment channel support.
        </div>
      </div>

      {showAuthorize && (
        <div
          onClick={() => setShowAuthorize(false)}
          className="z-20 bg-gray-800 bg-opacity-25 w-screen h-screen fixed top-0 left-0 flex items-center justify-center"
        >
          <div className="mx-auto max-w-lg w-full bg-white rounded p-8 flex flex-col">
            <Heading>Authorize Payment</Heading>

            <div className="p-2 rounded bg-gray-100 text-slate-500 my-4">
              Host: {paymentRequest?.paymentUrl}
            </div>
            <div className="my-8">
              {paymentRequest?.outputs.map((o) => `${o.amount} Satoshis\n`)}
            </div>
            <button
              className="px-4 py-2 bg-blue-400 hover:bg-blue-500 text-white font-semibold rounded"
              onClick={authorizePayment}
            >
              Authorize
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
